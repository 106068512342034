
      @import '/src/styles/common.scss';
      @import '/src/styles/font.scss';
      @import '/src/styles/mixins.scss';
      $cdnHost: 'https://websiteoss.ecoflow.com';
    
.pagelayout-bd {
    background-color: #fff;

    :global {
        .allContentContainer {
            position: relative;
            z-index: 1;
            min-height: 100vh;
        }
        .ecoflow-frame-header {
            width: 100%;
            min-height: 50px;
            position: sticky;
            z-index: 2100;
            top: 0;
            left: 0;
        }
        .ecoflow-frame-totop {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            position: fixed;
            right: 20px;
            bottom: 142px;
            z-index: 2000;
            border-radius: 50%;
            overflow: hidden;
            cursor: pointer;
            border: 1px solid hsla(0, 0%, 100%, 0.5);
            background: rgba(255, 255, 255, 0.98);
            box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
            opacity: 0;
            transition: opacity 0.5s;
            &.show-top {
                opacity: 1;
                &:hover {
                    background: #f0f0f0;
                }
            }
            @media only screen and (max-width: 1024px) {
                bottom: 130px;
                width: 40px;
                height: 40px;
            }
        }

        // 428 14Pro max
        // 390 14Pro
        @media only screen and (max-width: 446px) {
            .ecoflow-frame-header {
                min-height: 0.5rem;
            }
        }
    }
    &:global(.product_common_real),
    &:global(.ecoflow-isNoFixed) {
        :global {
            .ecoflow-frame-header {
                position: relative;
            }
        }
    }
    &:global(.black){
        :global{
            background-color: #000;
        }
    }
}
